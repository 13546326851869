require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import "bootstrap";

require('pickadate/lib/picker')
require('pickadate/lib/picker.date')
require('pickadate/lib/picker.time')

$(function() {
  $(".toggle").on("click", function() {
    if ($(this).hasClass("collapsed")) {
      $(this).removeClass("collapsed");
      $("#navbar-content").removeClass("show");
      $(this).html("<i class='fas fa-bars'></i>");
    } else {
      $(this).addClass("collapsed");
      $("#navbar-content").addClass("show");
      $(this).html("<i class='fas fa-times'></i>");
    }
  });
});

require("trix")
require("@rails/actiontext")