import consumer from "./consumer"

$(function() {
  var conversationUUID = $('#conversation-chat-container').data('conversation-uuid');
  var administratorId = $('#conversation-chat-container').data('administrator-id');
  console.log(administratorId);
  if (conversationUUID === undefined) return;

  consumer.subscriptions.create({
    channel: "NemuConversationChannel",
    uuid: conversationUUID
  }, {
    connected() {
      console.log(`connected to conversation ${conversationUUID}`);
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      console.log(data);
      var $chatbox = $('#chatbox');
      if (data.sender_class === 'Administrator') {
        var message = '<div class="d-flex justify-content-end">';
        message += '<div class="sender text-right">'
        message += `<small>${data.chat_name}</small>`;
        var context_data = data.context_data;
        if (context_data != null || context_data != undefined) {
          message += '<div class="card card-body">';
          message += '<div class="d-flex">';
          message += `<img width="80px" src="${context_data.thumb_url}">`;
          message += '<div class="content ml-3">';
          message += `<b>${context_data.title}</b>`;
          message += '<br>';
          message += `<small>${context_data.subtitle}</small>`
          message += '</div>';
          message += '</div>';
          message += '</div>';
        }
        message += `<p>${data.content}</p>`
        message += '</div>'
        message += '</div>'
        $chatbox.append(message);
      } else {
        var message = '<div class="d-flex justify-content-start">';
        message += '<div class="guest text-left">'
        message += `<small>${data.chat_name}</small>`;
        var context_data = data.context_data;
        if (context_data != null || context_data != undefined) {
          message += '<div class="card card-body">';
          message += '<div class="d-flex">';
          message += `<img width="80px" src="${context_data.thumb_url}">`;
          message += '<div class="content ml-3">';
          message += `<b>${context_data.title}</b>`;
          message += '<br>';
          message += `<small>${context_data.subtitle}</small>`
          message += '</div>';
          message += '</div>';
          message += '</div>';
        }
        message += `<p>${data.content}</p>`
        message += '</div>'
        message += '</div>'
        $chatbox.append(message);
      }
      $chatbox.scrollTop($chatbox.prop('scrollHeight'));
      $('#content').val('');
    }
  });
})

